import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { GreenLinkButton } from "./styled-components";

const ModalDisplay = styled("div")`
  width: 90vw;
  max-width: 500px;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  position: fixed;
  border: 5px solid #215732;
  border-radius: 5px;
  background-color: white;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    height: Calc(90vh -60px);
    margin-top: Calc(-45vh + 60px);
    margin-left: -45vw;
  }
`;

const BackDrop = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  opacity: 0.2;
  z-index: 15;
`;

const ThankYouModal = ({ handleClose }) => {
  return (
    <>
      <BackDrop />
      <ModalDisplay>
        <h3
          css={css`
            color: #215732;
          `}
        >
          Thank you for contacting BOW Renewables!
        </h3>
        <br />
        <h5
          css={css`
            color: #215752;
          `}
        >
          We'll be in touch shortly.
        </h5>
        <br />
        <br />
        <GreenLinkButton to="/" onClick={handleClose}>
          HOME
        </GreenLinkButton>
      </ModalDisplay>
    </>
  );
};

export default ThankYouModal;
