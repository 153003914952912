import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import {
  OrangeSection,
  WhiteSection,
  GreenBreak,
  OrangeBreak,
  PageBreakText,
  TitleText,
  BlurbWrapper,
  BlurbText,
  BlurbLink,
  TextImageWrapper,
} from "../components/styled-components";
import Hero from "../components/hero";
import HexTransition from "../components/hex-transition";
import MainLayout from "../components/main-layout";
import QuickLinks from "../components/quick-links";
import Contact from "../components/contact";
import ImageWrapper from "../components/image-wrapper";

const pathArray = [
  {
    path: "/about#us",
    title: "ABOUT US",
  },
  {
    path: "/about#contact",
    title: "CONTACT",
  },
];

const About = ({ data }) => {
  const whybowOne = data.allContentfulImage.nodes.find(
    (node) => node.slug === "whybow-1"
  );
  const whybowTwo = data.allContentfulImage.nodes.find(
    (node) => node.slug === "whybow-2"
  );
  const whybowThree = data.allContentfulImage.nodes.find(
    (node) => node.slug === "whybow-3"
  );
  const whybowFour = data.allContentfulImage.nodes.find(
    (node) => node.slug === "whybow-4"
  );

  return (
    <MainLayout>
      <Helmet>
        <title>BOW - Why BOW</title>
        <meta
          name="description"
          content="At BOW Renewables, we truly believe we are environmental stewards, and that it’s our duty to give back to the planet in a responsible yet cost-efficient way. These standards are woven through every step of your project. We treat each project as if it were our own, because in the end, we all share the planet together."
        />
      </Helmet>
      <OrangeSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <Hero
            fluid={data.contentfulHeroImage.heroImage.fluid}
            alt={data.contentfulHeroImage.alt}
            type={"WtoO"}
            center={true}
          />
          <QuickLinks pathArray={pathArray} transition={true} />
          <HexTransition type={"GtoW"} />
          <br />
        </div>
        <TitleText color="green">WHY BOW</TitleText>
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            At BOW Renewables, we truly believe we are environmental stewards,
            and that it’s our duty to give back to the planet in a responsible
            yet cost-efficient way. These standards are woven through every step
            of your project. We treat each project as if it were our own,
            because in the end, we all share the planet together.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </OrangeSection>
      <WhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <br />
        </div>
        <br />
        <br />
        <br />
        <TextImageWrapper reverse={true}>
          <div
            data-sal="slide-left"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              We understand that adopting renewable solar energy for your land
              or business can seem quite overwhelming. There is a lot to
              consider in getting from the initial “pie in the sky” idea to
              essentially flipping the switch on your solar energy powerhouse.
              We have expertise in every arena of solar conversion, and you can
              rely on us for any or all of our energy consulting services.
            </BlurbText>
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={whybowOne.image.fluid} alt={whybowOne.alt} />
          </div>
        </TextImageWrapper>
        <br />
        <br />
        <TextImageWrapper>
          <div
            data-sal="slide-left"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={whybowTwo.image.fluid} alt={whybowTwo.alt} />
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              Working with BOW Renewables means that you stay competitive in the
              energy economy market, while getting ahead of your competition by
              increasing your bottom line. Whether you want to power your
              existing{" "}
              <BlurbLink to="/gosolar#business" color="#1a4428" stripHash>
                business
              </BlurbLink>{" "}
              or{" "}
              <BlurbLink to="/gosolar#landowner" color="#1a4428" stripHash>
                use your land
              </BlurbLink>{" "}
              to “farm” the power of the sun, we help you make it happen.
            </BlurbText>
          </div>
        </TextImageWrapper>
        <br />
        <br />
        <br />
      </WhiteSection>
      <WhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <OrangeBreak height={"10vh"} minHeight={"100px"} id="us">
            <PageBreakText color="green">ABOUT US</PageBreakText>
          </OrangeBreak>
          <HexTransition type={"OtoW"} />
          <br />
          <br />
          <br />
          <TextImageWrapper>
            <div
              data-sal="slide-left"
              css={css`
                width: 40%;
                display: flex;
                justify-content: center;
                @media (max-width: 768px) {
                  margin-bottom: 20px;
                  width: 90vw;
                }
              `}
            >
              <ImageWrapper
                fluid={whybowThree.image.fluid}
                alt={whybowThree.alt}
              />
            </div>
            <div
              data-sal="slide-right"
              css={css`
                width: 50%;
                display: flex;
                flex-direction: column;
                text-align: center;
                @media (max-width: 768px) {
                  width: 90vw;
                }
              `}
            >
              <BlurbText color="#1a4428" margin={true}>
                Our team is a group of passionate renewable energy experts,
                committed to working with the environment, rather than against
                it. With over 30 years of combined energy development,
                management and engineering experience, BOW Renewables is
                committed to completing your project on budget, on time, and on
                target.
              </BlurbText>
            </div>
          </TextImageWrapper>
          <br />
          <br />
          <TextImageWrapper reverse={true}>
            <div
              data-sal="slide-left"
              css={css`
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @media (max-width: 768px) {
                  width: 90vw;
                }
              `}
            >
              <BlurbText color="#1a4428" margin={true}>
                We work with integrity, and that includes our internal culture.
                Our entire team works here because they WANT to work here. Each
                member of the team receives fully paid healthcare, and we take a
                progressive approach to a positive work-life balance. We’re a
                company that cares about the company we keep; we’re different by
                design.
              </BlurbText>
            </div>
            <div
              data-sal="slide-right"
              css={css`
                width: 40%;
                display: flex;
                justify-content: center;
                @media (max-width: 768px) {
                  margin-bottom: 20px;
                  width: 90vw;
                }
              `}
            >
              <ImageWrapper
                fluid={whybowFour.image.fluid}
                alt={whybowFour.alt}
              />
            </div>
          </TextImageWrapper>
          <br />
          <br />
          <br />
        </div>
      </WhiteSection>
      <GreenBreak height={"10vh"} minHeight={"100px"} id="contact">
        <PageBreakText>CONTACT</PageBreakText>
      </GreenBreak>
      <OrangeSection>
        <HexTransition type={"GtoW"} />
        <br />
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            We’re excited to hear from you. Please complete the form below and
            one of our expert consultants will respond as quickly as possible.
          </BlurbText>
        </BlurbWrapper>
        <br />
        <Contact />
      </OrangeSection>
    </MainLayout>
  );
};

export default About;

export const query = graphql`
  query AboutQuery {
    contentfulHeroImage(slug: { eq: "whybow-top" }) {
      heroImage {
        fluid(maxWidth: 3080, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroAlt
    }
    allContentfulImage(filter: { tags: { in: "whybow" } }) {
      nodes {
        alt
        slug
        image {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
