import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/core";
import "../styles/contact.css";

import { useInput } from "../hooks/use-input";

import ThankYouModal from "./thank-you-modal";

// const whoMap = {
//   "land-agricultural": "Land / Agricultural",
//   "commercial-industrial": "Commercial / Industrial",
//   "not-sure": "No Specified Area",
// };

const Contact = () => {
  const { value: name, bind: bindName, reset: resetName } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  );
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [thankYouModal, setThankYouModal] = useState(false);
  const contactForm = useRef(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const form = {
      "form-name": contactForm.current.getAttribute("name"),
      "name": name,
      "email": email,
      "message": message,
      "land-agricultural": contactForm.current.elements[3].checked,
      "commercial-industrial": contactForm.current.elements[4].checked,
      "not-sure": contactForm.current.elements[5].checked,
      // "area-of-interest": Array.from(contactForm.current.elements).find((ele) => ele.checked).value,
    }
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(form).toString()
    })
      .then((res) => {
        setSent(true);
        resetName();
        resetEmail();
        resetMessage();
      })
      .catch((err) => setError(err.toString()));
  };

  const handleClose = () => {
    setThankYouModal(false);
  };

  useEffect(() => {
    if (sent) {
      setThankYouModal(true);
      setSent(false);
    }
  }, [sent]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setError(null);
    }
  }, [error]);

  return (
    <>
      {thankYouModal && <ThankYouModal handleClose={handleClose} />}
      <form
        className="contact-form"
        name="contact"
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit}
        ref={contactForm}
      >
        <br />
        <label className="form-label" htmlFor="form-name">
          Name:
          <input
            className="form-input"
            id="form-name"
            type="text"
            name="name"
            value={name}
            {...bindName}
          />
        </label>

        <label className="form-label" htmlFor="form-email">
          Email:
          <input
            className="form-input"
            id="form-email"
            type="email"
            name="email"
            value={email}
            {...bindEmail}
          />
        </label>
        <br />
        <fieldset
          css={css`
            border-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          `}
        >
          <legend
            css={css`
              font-family: Montserrat;
              font-weight: 600;
              font-size: 1.2rem;
              margin: 0 auto 20px auto;
            `}
          >
            Area of Interest?
          </legend>
          <p className="radio-label">
            <label className="label-wrapper">
              Land / Agricultural:
              <input
                className="radio-input"
                type="radio"
                id="land-agricultural"
                name="land-agricultural"
                value="Land / Agricultural"
              />
            </label>
          </p>
          <p className="radio-label">
            <label className="label-wrapper">
              Commercial / Industrial:
              <input
                className="radio-input"
                type="radio"
                id="commercial-industrial"
                name="commercial-industrial"
                value="Commercial / Industrial"
              />
            </label>
          </p>
          <p className="radio-label">
            <label className="label-wrapper">
              Not Sure:
              <input
                className="radio-input"
                type="radio"
                id="not-sure"
                name="not-sure"
                value="Not Sure"
              />
            </label>
          </p>
        </fieldset>
        <br />
        <label className="form-label" htmlFor="form-message">
          Comment:
          <textarea
            className="form-area"
            id="form-message"
            name="message"
            value={message}
            {...bindMessage}
          ></textarea>
        </label>

        <button className="submit-button" type="submit">
          Send
        </button>
      </form>
    </>
  );
};

export default Contact;
